<template>    
  <div class='flex flex-col lg:flex-row justify-center lg:justify-start gap-y-4 lg:gap-y-0 lg:gap-x-8'>
    <div class='lg:mt-4 w-full lg:w-1/4 lg:flex-shrink-0 flex flex-col justify-between'>
      <h1 class='font-semibold uppercase text-2xl lg:text-3xl'>
        {{ myPageLikedSessionsTitle }}
      </h1>
    </div>
    <div class='rounded-lg flex-grow'>
      <div v-if='hasMultipleDays'
        class='flex flex-row justify-start items-center mt-4 border-b'>
        <div v-for='(day) in daysWithSessions'
          :key='`day-tab-${day}`'
          class='py-2 lg:py-3 px-2 lg:px-8 text-sm lg:text-base border-b-4 border-transparent uppercase cursor-pointer hover:bg-gray-100'
          :class='selectedDayStyle(day)'
          @click='selectedDay = day'>
          {{ day }}
        </div>
      </div>
      <div>
        <div v-for='(session, index) in filteredLikedSessions'
          :key='`liked_session-${index}`'
          class='standard-transition flex flex-row justify-start items-start gap-x-8 bg-gray-50 px-2 py-4 mb-4 rounded text-sm hover:shadow-md cursor-pointer hover:rounded-md'
          @click='openProgramSessionDetailsModal(session.id)'>
          <div class='w-1/4 flex-grow-0 flex-shrink-0 text-xs lg:text-sm'>
            <div :style='themeTextColorStyle'>{{sessionDateString(session)}}</div>
            <div>{{sessionScheduledTimeString(session)}}</div>
            <div class='uppercase'>{{sessionLocationString(session)}}</div>
          </div>
          <div>
            <div class='font-semibold'>
              {{session.title}}
            </div>
            <div class='text-gray-600'>{{session.subtitle}}</div>
          </div>
        </div>
      </div>
      <div v-if='filteredLikedSessions.length === 0'
        class='my-2 py-16 bg-gray-100 rounded-md text-gray-600 text-center'>
        No liked sessoins
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters }   from 'vuex'
import dayjs from 'dayjs'
import sortedUniq from 'lodash/sortedUniq'

export default {
  name: 'MyPageLikedSessions',
  components: {
  },
  data () {
    return {
      mylikedSessions: [],
      selectedDay: '',
    }
  },
  watch: {
    hasMultipleDays: {
      handler (newVal) {
        if (newVal) {
          this.selectedDay = this.daysWithSessions[0]
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters('events',[
      'eventMainThemeColor',
      'themeTextColorStyle',
      'colorsInRange',
      'showingEventMyPageConfigurations',
    ]),
    myPageLikedSessionsConfigs () {
      return this.showingEventMyPageConfigurations && this.showingEventMyPageConfigurations.myPageLikedSessionsConfigs ? this.showingEventMyPageConfigurations.myPageLikedSessionsConfigs : null
    },
    myPageLikedSessionsTitle () {
      return this.myPageLikedSessionsConfigs && this.myPageLikedSessionsConfigs.title ? this.myPageLikedSessionsConfigs.title : 'Liked Sessions'
    },
    daysWithSessions () {
      return sortedUniq(this.mylikedSessions.map(session => dayjs(session.scheduledStartTime).format('MM.DD (ddd)')))
    },
    hasMultipleDays () {
      return this.daysWithSessions.length > 1
    },
    filteredLikedSessions () {
      return (this.hasMultipleDays)
        ? this.mylikedSessions.filter(session => dayjs(session.scheduledStartTime).format('MM.DD (ddd)') === this.selectedDay) 
        : this.mylikedSessions
    },
  },
  methods: {
    ...mapActions([
      'openProgramSessionDetailsModal',
    ]),
    ...mapActions('sessions', [
      'getMyLikedSessions',
    ]),
    selectedDayStyle (day) {
      return (day === this.selectedDay) ? 'selectedDayStyle' : ''
    },
    sessionLocationString (session) {
      return session.locations.map(location => location.split('||')[0]).join(', ')
    },
    sessionDateString (session) {
      return `${dayjs(session.scheduledStartTime).format('YYYY.MM.DD (ddd)')}`
    },
    sessionScheduledTimeString (session) {
      return `${dayjs(session.scheduledStartTime).format('HH:mm')} - ${dayjs(session.scheduledEndTime).format('HH:mm')}`
    },
  },
  mounted () {
    this.getMyLikedSessions().then(resp => {
      this.mylikedSessions = resp
    })
  },
}
</script>

<style lang='scss' scoped>
  .selectedDayStyle {
    @apply font-semibold;
    border-bottom-color: var(--eventMainColor);
  }
</style>